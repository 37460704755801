import React from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../../helpers/seo'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { PageContent } from '../../components/PageContent'
import { Heading } from '../../components/Heading'
import { Container } from '../../components/Container'
import { SPACING, COLORS } from '../../helpers/constants'
import { Divider } from '../../components/Divider'
import { GlossarySingleContent } from '../../components/GlossarySingleContent'
import { usePageData } from '../../helpers/utilities'
import { useTranslation } from '../../hooks/useTranslation'

export default ({ data, pageContext }) => {
  const { word, words } = usePageData(data, pageContext)
  const { blocks = [], seo, title, id } = word
  const currentID = id
  let parsedWords = words.nodes

  if (parsedWords)
    parsedWords.slice().sort((a, b) => a.title.localeCompare(b.title))

  const getTranslation = useTranslation()

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <Container>
          <Heading size={1} type={'h2'} color={COLORS.PRIMARY_RED}>
            {getTranslation('ebikeWordListText')}
          </Heading>
          <Divider
            size={{
              desktop: SPACING.XX_LARGE,
              tablet: SPACING.LARGE,
              mobile: SPACING.SMALL,
            }}
          />
          <GlossarySingleContent
            word={word}
            title={title}
            blocks={blocks}
            parsedWords={parsedWords}
            currentID={currentID}
          />
        </Container>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      word(id: $id) {
        ...Single_Word
      }
    }
  }
`
