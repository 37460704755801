import React from 'react'
import PropTypes from 'prop-types'
import StyledGlossarySidebar from './style'

const GlossarySidebar = props => {
  const { children } = props
  return <StyledGlossarySidebar>{children}</StyledGlossarySidebar>
}

GlossarySidebar.propTypes = {
  children: PropTypes.any,
}

export { GlossarySidebar }
