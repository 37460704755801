import React from 'react'
import PropTypes from 'prop-types'
import StyledGlossarySingleContent from './style'
import { GlossarySidebar } from '../GlossarySidebar'
import { TextLink } from '../TextLink'
import { Grid } from '../Grid'
import { Col } from '../Col'
import { Container } from '../Container'
import { Heading } from '../Heading'
import { Divider } from '../Divider'
import { WpBlocksGenerator } from '../../helpers/wpBlocksGenerator'
import { SPACING } from '../../helpers/constants'
import { getUrl } from '../../../node/functions'

const GlossarySingleContent = props => {
  const { word, title, blocks, parsedWords, currentID } = props
  let active = false
  return (
    <StyledGlossarySingleContent>
      <Grid col={3}>
        <Col>
          <GlossarySidebar>
            <Grid rowGap={'small'}>
              {parsedWords.map((item, index) => {
                const { id, uri, title } = item
                id === currentID ? (active = true) : (active = false)
                return (
                  <TextLink
                    key={id}
                    to={getUrl(uri)}
                    text={title}
                    inglossary
                    active={active}
                  />
                )
              })}
            </Grid>
          </GlossarySidebar>
        </Col>
        <Col column={'2 / -1'}>
          <Container>
            <Heading size={2} type={'h1'}>
              {title}
            </Heading>
            <Divider
              size={{
                desktop: SPACING.SMALL,
                tablet: SPACING.X_SMALL,
                mobile: SPACING.XX_SMALL,
              }}
            />
          </Container>
          <Divider
            size={{
              desktop: SPACING.SMALL,
              tablet: SPACING.SMALL,
              mobile: SPACING.SMALL,
            }}
          />
          <WpBlocksGenerator blocks={blocks} post={word} />
        </Col>
      </Grid>
    </StyledGlossarySingleContent>
  )
}

GlossarySingleContent.propTypes = {
  words: PropTypes.any,
  parsedWords: PropTypes.any,
  title: PropTypes.string,
  blocks: PropTypes.any,
}

export { GlossarySingleContent }
