import styled from 'styled-components'
/* Path below could differ */
import { mq } from '../../styles/utilities/variables'

const StyledGlossarySingleContent = styled.div`
  h1 {
    @media ${mq.mobile} {
      font-size: 3.2rem;
      hyphens: auto;
    }
  }
  .col {
    @media ${mq.mobile} {
      grid-column: 1 / -1;
    }

    &:first-child {
      @media ${mq.mobile} {
        order: 2;
      }
    }

    &:last-child {
      @media ${mq.mobile} {
        order: 1;
      }
    }

    & > * {
      padding-right: 0;
      padding-left: 0;
    }
  }
`

export default StyledGlossarySingleContent
